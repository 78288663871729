import React from "react";

import Layout from "../components/layout-main";

const MainPage = ({ location }) => {
  if (typeof window !== "undefined") {
    window.location = "https://afterword.com/";
    return;
  }
  return (
    <Layout
      location={location}
      hideFooter
      hideSubscribe
      pageTitle="Making Funeral Planning Easier for Everyone"
    ></Layout>
  );
};

export default MainPage;
